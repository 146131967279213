class FileUploadHelper {
  data = []

  addData(key, value) {
    this.data.push({ [key]: value }) // Es6 way of setting dynamic keys to an object
  }

  getUploadData() {
    const formData = new FormData()

    for (let i = 0; i < this.data.length; i++) {
      let key = Object.keys(this.data[i])[0]
      let value = this.data[i][key]
      formData.append(key, value)
    }

    return formData
  }
}
export default FileUploadHelper
